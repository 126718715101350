<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_category"
        data-wizard-state="step-first"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">

            <div class="wizard-step my-1" data-wizard-type="step" :data-wizard-state="step.state" v-for="step in steps" :key="step.title">
              <div class="wizard-wrapper">
                <div class="wizard-label">
                  <h3 class="wizard-title mb-0">
                    {{ step.title }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Kategorinin adı nedir?
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Kategorinize bir isim verin
                  </p>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                          Türkçe
                          <Flag class="ml-2" code="TR" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                        </label>
                        <input type="text" placeholder="Adını girin" class="form-control form-control-lg" autocomplete="off" name="categoryNameTR" @blur="$v.categoryNameTR.$touch()" v-model="categoryNameTR">
                        <span class="form-text text-danger" v-if="$v.categoryNameTR.$error">Bu alan boş geçilemez.</span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                          İngilizce
                          <Flag class="ml-2" code="GB-UKM" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                        </label>
                        <input type="text" placeholder="Adını girin" class="form-control form-control-lg" autocomplete="off" name="categoryNameEN" @blur="$v.categoryNameEN.$touch()" v-model="categoryNameEN">
                        <span class="form-text text-danger" v-if="$v.categoryNameEN.$error">Bu alan boş geçilemez.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Kategoriyi bir cümle ile açıklayın
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Kategorinizi kısaca anlatın
                  </p>
                  <div class="form-group">
                    <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                      Türkçe
                      <Flag class="ml-2" code="TR" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                    </label>
                    <textarea name="categoryDescTR" class="form-control form-control-lg" autocomplete="off" rows="2" v-model="categoryDescTR"></textarea>
                  </div>
                  <div class="form-group">
                    <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                      İngilizce
                      <Flag class="ml-2" code="GB-UKM" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                    </label>
                    <textarea name="categoryDescEN" class="form-control form-control-lg" autocomplete="off" rows="2" v-model="categoryDescEN"></textarea>
                  </div>
                </div>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 2-->
                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Kategori ikonu
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Kategoriyi en iyi anlatan uygun formatta bir ikon yükleyin.
                  </p>
                  <div class="form-group row">
                    <div class="col-xl-7 col-lg-6">
                      <file-pond
                          name="icon"
                          allow-multiple="false" 
                          accepted-file-types="image/*"
                          max-files="1" 
                          maxFileSize="20KB"
                          :file-rename-function="renameFile" 
                          v-on:removefile="removeFile"
                          :server="{ process }"
                          label-idle='<div style="width:100%;height:100%;">
                            <h3 style="font-size: 16px">
                              Drag &amp; Drop your files or <span class="filepond--label-action" tabindex="0">Browse</span><br>
                            </h3>
                          </div>'
                        />
                        <input type="hidden" name="categoryIcon" v-model="categoryIcon">
                        <span class="form-text text-danger" v-if="$v.categoryIcon.$error">Bu alan boş geçilemez.</span>
                    </div>
                    <div class="col-xl-5 col-lg-6">
                      <div class="d-flex flex-column justify-content-between flex-wrap">
                        <div class="d-flex align-items-center justify-content-end flex-wrap">
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">20 KB</span>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <span class="text-dark-65 font-weight-bolder">SVG</span>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <div class="d-flex flex-column align-items-center">
                                <span class="text-dark-65 font-weight-light">Width</span>
                                <span class="text-dark-65 font-weight-bolder">250 px</span>
                              </div>
                            </div>
                          </div>
                          <div class="symbol symbol-70 flex-shrink-0 mr-4 mb-4">
                            <div class="symbol-label">
                              <div class="d-flex flex-column align-items-center">
                                <span class="text-dark-65 font-weight-light">Height</span>
                                <span class="text-dark-65 font-weight-bolder">250 px</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>            
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 2-->

                 <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      class="btn btn-secondary font-weight-bold px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Geri
                    </button>
                  </div>
                  <div>
                    <button
                      v-on:click="submit"
                      class="btn btn-dark font-weight-bold px-9 py-4"
                      ref="kt_create_category"
                      data-wizard-type="action-submit"
                    >
                      Kaydet
                    </button>
                    <button
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      Sonraki Adım
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->

              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import JwtService from '@/core/services/jwt.service';
import { validationMixin } from "vuelidate";
import { required} from "vuelidate/lib/validators";

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm.js';
import FilePondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm.js';
import 'filepond/dist/filepond.min.css';
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginFileRename);

export default {
  mixins: [validationMixin],
  name: 'CreateCategoryWizard',
  components: {
    FilePond
  },
  data() {
    return {
      steps: [
        {
          title: 'Kategorinin Adı',
          state: 'current'
        },
        {
          title: 'Açıklama',
          state: 'pending'
        },
        {
          title: 'İkon',
          state: 'pending'
        },
      ],

      categoryNameTR: '',
      categoryNameEN: '',
      categoryDescTR: '',
      categoryDescEN: '',
      categoryIcon: ''
    }
  },  
  validations: {
    categoryNameTR: {
      required
    },
    categoryNameEN: {
      required
    },
    categoryIcon: {
      required
    }
  },
  mounted() {
    const self = this;

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_category", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    // Change event
    wizard.on("change", function(wizardObj) {
      switch (wizardObj.getStep()) {
        case 1:
          self.$v.categoryNameTR.$touch();
          self.$v.categoryNameEN.$touch();
          if (self.$v.categoryNameTR.$invalid || self.$v.categoryNameEN.$invalid) {
            wizardObj.stop();
          }
          break;
      }
    });
  },
  methods: {
    process (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      formData.append('image', file, file.name);

      const request = new XMLHttpRequest();
      request.open("POST", "https://api.zenofontours.com/admin/v1/category/uploadIcon.req.php");
      request.setRequestHeader('Authorization', JwtService.getToken());

      request.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      const self = this;
      request.onload = function() {
        if (request.status >= 200 && request.status < 300) {
          self.categoryIcon = file.name;
          load();
        } else {
          // Can call the error method if something is wrong, should exit after
          self.categoryIcon = '';
          error();
        }
      };

      request.send(formData);

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        }
      }; 
    },

    renameFile(file) {
      return Date.now() + '_' + file.name;
    },

    removeFile() {
      this.newIcon = '';
    },

    submit: function(e) {
      e.preventDefault();

      this.$v.categoryIcon.$touch();
      if (this.$v.categoryIcon.$invalid) { return; };

      const submitButton = this.$refs["kt_create_category"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const data = {
        title: JSON.stringify({
          tr: this.categoryNameTR,
          en: this.categoryNameEN
        }),
        summary: JSON.stringify({
          tr: this.categoryDescTR,
          en: this.categoryDescEN
        }),
        icon: this.categoryIcon
      }
      ApiService.post('category/create.req.php', data)
      .then(({ data }) => {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        this.$router.push({ name: 'show-category', params: { id: data.categoryId } });
      })
      .catch(({ response }) => {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

.filepond--wrapper {
  height: 100%;
}
.filepond--panel-root {
  background-color: transparent !important;
  border: 3px dashed #EBEDF3;
}
.filepond--wrapper.invalid .filepond--panel-root {
  border-color: #F64E60 !important;
}
.filepond--drop-label {
  background-color: transparent !important;
  height: 100% !important;
}
.filepond--root.filepond--hopper {
  height: 100% !important;
}
.filepond--root .filepond--drop-label {
  height: 100% !important;
}
</style>