<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between mb-8">
      <h2 class="font-weight-bold my-2 mr-5">
        Kategori Oluştur
      </h2>
      <div class="d-flex flex-row align-items-center">
      <router-link
        :to="{ name: 'categories'}"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
          <span class="d-none d-md-inline">Kategoriler</span>
        </a>
      </router-link>
      </div>
    </div>
    <CreateCategoryWizard></CreateCategoryWizard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateCategoryWizard from "@/view/pages/category/components/CreateCategoryWizard";

export default {
  name: "CreateCategory",
  components: {
    CreateCategoryWizard
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "CreateDestinaton" }]);
  },
}
</script>

<style>

</style>